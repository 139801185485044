/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import { getAxiosData, getAxiosDataNode, getAxiosDatas } from '@/services/apiConfig';
import { getAxiosData, getAxiosDataNode, getAxiosDatas } from '@/services/apiConfig';
import { Result } from '@/types/services';
import { AgePortraitS, AoiParams, CityFlowTopS, CompentedDataParams, CompentedParams, CustomerParams, CustomerParamsStartEnd, FlowNumS, GridParams, MallBusinessCore, MallRankParams, MallRanksParams, MarketPenetration, PassengerFlowS, PassengerFlowTopsParams, PassengerFlowTopsParamsArray, PerAgeNumTheSumParams, PerAgePortraitSParams, PerAgePortraitSParamsArray, StayHour, StayHourParams, TotalPassengerFlowParams } from '@/types/store';
/* eslint-disable @typescript-eslint/naming-convention */

/*
* 接口类型：Post
* 编码类型：Content-Type:application/json
*/

async function getMallFlowAndNumDataByMallId(params: CustomerParamsStartEnd, showInfoWindow = true): Promise<any> {
  /*
   * 客流统计查询接口
   * 根据mall_id、日期，获取商场的基础数据，基础数据包括（到访人次、到访人数、人次/人数比、新客流人数、新客流占比）。
   */
  const url = 'getMallFlowAndNumDataByMallId';
  const getData = await getAxiosData(params, url, 'inners_api/', showInfoWindow);
  const res: any = getData as Result<any>;
  const { data, sum_obj: sumObj } = res;
  const times: Array<string> = [];
  const flows: Array<string> = [];
  const newNums: Array<string> = [];
  const newPercents: Array<string> = [];
  const nums: Array<string> = [];
  const percents: Array<string> = [];
  const oldNums: Array<string> = [];
  const dates: Array<string> = [];
  const datas: FlowNumS[] = data ? Object.values(data) : [];
  const dataObj: any = {};
  datas.forEach((item) => {
    const { time, flow, new_num, new_percent, num, percent, old_num } = item;
    let day: any = time.split(' ')[0].split('-');
    day = `${day[1]}-${day[2]}`;
    times.push(day);
    flows.push(flow);
    newNums.push(new_num);
    newPercents.push(new_percent);
    nums.push(num);
    percents.push(percent);
    oldNums.push(old_num);
    dates.push(time);
    dataObj[day] = item;
  });
  return {
    times,
    flows,
    newNums,
    newPercents,
    nums,
    oldNums,
    percents,
    dates,
    data,
    dataObj,
    datas,
    sumObj: sumObj || {},
  };
}

async function getLocaltiyAndOutsidePassengerFlowS(params: CustomerParams): Promise<any> {
  /*
   * 顾客来源地分析趋势
   * 按照顾客来源地分为：本地顾客、外地顾客、本地排名、外地排名、总顾客数、总排名。近31天内，不同分类顾客数量和外地顾客占比的变化。
   */
  const url = 'getLocaltiyAndOutsidePassengerFlowS';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const { data } = res;
  const times: Array<string> = [];
  const localtiyNums: Array<string> = [];
  const localtiyRatios: Array<string> = [];
  const outsideNums: Array<string> = [];
  const outsideRadios: Array<string> = [];
  const sums: Array<string> = [];
  const ranks: Array<string> = [];
  const dates: Array<string> = [];
  const datas: PassengerFlowS[] = Object.values(data);
  const datas2: any = [];
  const dataObj: any = {};
  datas.forEach((item) => {
    if (typeof(item) === 'object') {
      const { time, localtiy_num, localtiy_ratio, outside_num, outside_ratio, rank, sum } = item;
      let day: any = time.split(' ')[0].split('-');
      day = `${day[1]}-${day[2]}`;
      times.push(day);
      localtiyNums.push(localtiy_num);
      localtiyRatios.push(localtiy_ratio);
      outsideNums.push(outside_num);
      outsideRadios.push(outside_ratio);
      ranks.push(rank);
      sums.push(sum);
      dates.push(time);
      dataObj[day] = item;
      // eslint-disable-next-line no-param-reassign
      item.time = item.time.split(' ')[0] as string;
      datas2.push(item);
    }
  });
  return {
    times,
    localtiyNums,
    localtiyRatios,
    outsideNums,
    outsideRadios,
    ranks,
    sums,
    dates,
    dataObj,
    datas: datas2,
  };
}

async function getGridPerAgePortraitByMallIdS(params: PerAgePortraitSParams): Promise<any> {
  /*
   * 客流到访频次查询接口
   * 根据mall_id、日期，获取商场近7天或30天的客流到访频次。
   */
  const url = 'getGridPerAgePortraitByMallIdS';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const data = processData(res.data, 0);
  return {
    data,
    datas: Object.values(res.data || {}),
  };
}

async function getCityOutsidePassengerFlowTopS(params: PassengerFlowTopsParams): Promise<any> {
  /*
   * 外地客群城市分布查询接口
   * 根据mall_id、日期，获取外地客群城市到访的TOP10。
   */
  const url = 'getCityOutsidePassengerFlowTopS';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const { data } = res;
  const citys: Array<string> = [];
  const percents: Array<string> = [];
  const datas: CityFlowTopS[] = Object.values(data);
  datas.forEach((item) => {
    const { city, percent } = item;
    citys.push(city);
    percents.push(percent);
  });
  return {
    citys,
    percents,
    datas,
  };
}
async function getCityOutsidePassengerFlowTopS1(params: PassengerFlowTopsParamsArray): Promise<any> {
  /*
   * 外地客群城市分布查询接口
   * 根据mall_id、日期，获取外地客群城市到访的TOP10。
   */
  const url = 'getCityOutsidePassengerFlowTopS1';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const { data } = res;
  const citys: Array<string> = [];
  const percents: Array<string> = [];
  const datas: CityFlowTopS[] = Object.values(data || {});
  datas.forEach((item) => {
    const { city, percent } = item;
    citys.push(city);
    percents.push(percent);
  });
  return {
    citys,
    percents,
    datas,
  };
}
async function getLengthsOfStayData(params: StayHourParams): Promise<any> {
  /*
   * 客流停留时长查询接口
   * 根据mall_id、日期，获取商场当天的客流停留时长。
   */
  const url = 'getLengthsOfStayData';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const { data } = res;
  const texts: Array<string> = [];
  const percents: Array<string> = [];
  const datas: StayHour[] = Object.values(data || {});
  datas.forEach((item) => {
    const { text, percent } = item;
    texts.push(text);
    percents.push(percent);
  });
  return {
    texts,
    percents,
    datas,
  };
}

async function getGridPerAgePortraitS(params: PerAgePortraitSParamsArray): Promise<any> {
  const url = 'getGridPerAgePortraitS';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const data = processData(res.data, 1);
  return {
    data,
    datas: Object.values(res.data || {}),
  };
}

//  画像到访4s店
async function getGridPerAgePortraitInterceptS(params: PerAgePortraitSParams): Promise<any> {
  const url = 'getGridPerAgePortraitInterceptS';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const data = processData(res.data, 0, params.sort);
  return {
    data,
    datas: Object.values(res.data || {}),
  };
}
async function getMallTotalPassengerFlow(params: TotalPassengerFlowParams): Promise<any> {
  const url = 'getMallTotalPassengerFlow';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  return res;
}
async function getGridPerAgePortraitByMallId(params: PerAgePortraitSParams, showInfoWindow = true): Promise<any> {
  // const url = 'getGridPerAgePortraitByMallId';
  // const getData = await getAxiosData(params, url);
  // const res = getData as Result<any>;
  // const data = processData(res.data, 0, params.sort);
  // return {
  //   data,
  //   datas: Object.values(res.data || {}),
  // };
  const url = 'userprofile/getuserprofile';
  const getData = await getAxiosDataNode(params, url, showInfoWindow);
  const res = getData as Result<any>;
  const data = processDataNode(res.data, 0, params.sort);
  return {
    data,
    datas: res.data || {},
  };
}

async function getAoiPortrait(params: PerAgePortraitSParams): Promise<any> {
  const url = 'getAoiPortrait';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const data = processData(res.data, 0, params.sort);
  return {
    data,
    datas: Object.values(res.data || {}),
  };
}

async function getGridMallPerAgeNumTheSum(params: PerAgeNumTheSumParams, showInfoWindow = true): Promise<any> {
  const url = 'getGridMallPerAgeNumTheSum';
  const getData = await getAxiosData(params, url, 'inners_api/', showInfoWindow);
  const res = getData as Result<any>;
  const data = processAgeNumTheSumData(res.data || []);
  return {
    data,
    datas: Object.values(res.data || {}),
  };
}

async function getGridMallAnalysisConsumptionAbility(params: PassengerFlowTopsParams): Promise<any> {
  const url = 'getGridMallAnalysisConsumptionAbility';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const data = processData2(res.data || {});
  const { totalConsumption, consumptionIndex } = data;
  return {
    totalConsumption,
    consumptionIndex,
    datas: Object.values(res.data || {}),
  };
}

async function getAoiDepressionRank(params: AoiParams): Promise<any> {
  /*
   * 客源地排行查询接口
   * 根据key、mall_id、日期，编码类型 ，获取AOI区域（居住、工作、过路）三种不同类型的洼地区域。
   */
  const { url } = params;
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  const { data } = res;
  return data;
}

async function getGridDepressionData(params: GridParams, showInfoWindow = true): Promise<any> {
  /*
   * 栅格渗透率查询接口
   * 接口描述：根据mall_id、日期，获取栅格渗透率，渗透率=客流人数/人口数。
   */
  // const { url } = params;
  const url = 'gridprofile/getGridDepressionData';
  const getData = await getAxiosDataNode(params, url, showInfoWindow);
  const res = getData as Result<any>;
  return res;
}

async function getGridBussinessCircleS(params: GridParams): Promise<any> {
  /*
   * 栅格渗透率查询接口
   * 接口描述：根据mall_id、日期，获取栅格渗透率，渗透率=客流人数/人口数。
   */
  // const { url } = params;
  const url = 'gridprofile/getGridBussinessCircleS';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

async function getPassengerFlowDistribute(params: any): Promise<any> {
  /*
   * 栅格渗透率查询接口
   * 接口描述：根据mall_id、日期，获取栅格渗透率，渗透率=客流人数/人口数。
   */
  // const { url } = params;
  const url = 'gridprofile/getPassengerFlowDistribute';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

async function getMallRankDataByType(params: MallRankParams): Promise<any> {
  /*
   * 城市全部商场排行数据查询接口
   * 获取城市全部商场排行接口,根据城市编码、日期、不同的type类型获取不同维度的商场数据。
   */
  const url = 'ranking/getMallRankDataByType';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
async function getMallRankDataByTypes(params: MallRanksParams): Promise<any> {
  /*
   * 城市全部商场排行数据查询接口
   * 获取城市全部商场排行接口,根据城市编码、日期、不同的type类型获取不同维度的商场数据。
   */
  const url = 'ranking/getMallRankDataByTypes';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
async function getCompetedMallByUserId(params: CompentedParams): Promise<any> {
  /*
   * 竞品区域查询接口
   * 获取主场绑定的竞品区域。
   */
  const url = 'usercmid/getCompetedMallByUserId';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// async function getCompetedMallByUserId(params: CompentedParams): Promise<any> {
//   /*
//    * 竞品区域查询接口
//    * 获取主场绑定的竞品区域。
//    */
//   const url = 'getCompetedMallByUserId';
//   const getData = await getAxiosData(params, url);
//   const res = getData as Result<any>;
//   return res;
// }
async function getCompetedMallIds(params: CompentedDataParams): Promise<any> {
  /*
   * 竞品区域查询接口
   * 获取主场绑定的竞品区域。
   */
  const url = 'getCompetedMallIds';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  return res;
}

async function getCompetitionIntensityData(params: any, showInfoWindow = true): Promise<any> {
  /*
   * 竞争烈度查询接口
   * 获取主场竞品竞争情况
   */
  const url = 'gridprofile/getCompetitionIntensityData';
  const getData = await getAxiosDataNode(params, url, showInfoWindow);
  const res = getData as Result<any>;
  return res;

  // const url = 'getCompetitionIntensityData';
  // const getData = await getAxiosData(params, url, 'inners_api/', showInfoWindow);
  // const res = getData as Result<any>;
  // return res;
}

function processAgeNumTheSumData(data: any): any {
  const manData: Array<string> = [];
  const menData: Array<string> = [];
  const texts: Array<string> = [];
  data.forEach((item: any) => {
    const { text, num_percent, sex } = item;
    if (sex === 'man') {
      manData.push(num_percent);
    } else {
      const text2 = text.slice(2, text.length - 1);
      texts.push(text2);
      menData.push(num_percent);
    }
  });
  return {
    manData,
    menData,
    texts,
  };
}

function processData(data: AgePortraitS[], type: number, sort = false): any {
  const percents: Array<string> = [];
  const texts: Array<string> = [];
  const propertys: Array<string> = [];
  const datas: AgePortraitS[] = Object.values(data || {});
  if (sort) {
    datas.sort((a: any, b: any) => b.percent - a.percent);
  }
  datas.forEach((item) => {
    const { text, percent, property } = item;
    let text2 = text;
    if (type === 1) {
      text2 = text.slice(0, 5);
    }
    percents.push(percent);
    texts.push(text2);
    propertys.push(property);
  });
  return {
    percents,
    texts,
    propertys,
  };
}
function processDataNode(data: AgePortraitS[], type: number, sort = false): any {
  const percents: Array<string> = [];
  const texts: Array<string> = [];
  const propertys: Array<string> = [];
  const datas: AgePortraitS[] = data;
  if (sort) {
    datas.sort((a: any, b: any) => b.percent - a.percent);
  }
  datas.forEach((item) => {
    const { text, percent, property } = item;
    let text2 = text;
    if (type === 1) {
      text2 = text.slice(0, 5);
    }
    percents.push(percent);
    texts.push(text2);
    propertys.push(property);
  });
  return {
    percents,
    texts,
    propertys,
  };
}

function processData2(data: any): any {
  const manData: Array<string> = [];
  const menData: Array<string> = [];
  const texts: Array<string> = [];
  const { total_consumption: totalConsumption, consumption_index: consumptionIndex } = data;
  if (totalConsumption) {
    totalConsumption.forEach((item: any) => {
      const { text, num, sex } = item;
      if (sex === 'man') {
        manData.push(num);
      } else {
        const text2 = text.slice(2, text.length - 1);
        texts.push(text2);
        menData.push(num);
      }
    });
  }
  const texts2: Array<string> = [];
  const centerAry: Array<number> = [];
  const highAry: Array<number> = [];
  const higherAry: Array<number> = [];
  const lowAry: Array<number> = [];
  const lowerAry: Array<number> = [];

  if (consumptionIndex) {
    consumptionIndex.forEach((item: any) => {
      const { text, center, high, higher, low, lower } = item;
      texts2.push(text);
      centerAry.push(center);
      highAry.push(high);
      higherAry.push(higher);
      lowAry.push(low);
      lowerAry.push(lower);
    });
  }

  return {
    totalConsumption: {
      manData,
      menData,
      texts,
    },
    consumptionIndex: {
      centerAry,
      highAry,
      higherAry,
      lowAry,
      lowerAry,
      texts: texts2,
    },
  };
}
// 核心商圈
async function getMallBusinessCore(params: MallBusinessCore): Promise<any> {
  const url = 'getMallBusinessCirclesByMallId';
  const getData = await getAxiosData(params, url);
  const res = getData as Result<any>;
  return res;
}
// 市场渗透
async function getMarketPenetrationSvc(params: MarketPenetration, showInfoWindow = true): Promise<any> {
  const url = 'getGridMallPerAgeNumTheSum';
  const getData = await getAxiosData(params, url, 'inners_api/', showInfoWindow);
  const res = getData as Result<any>;
  return res;
}
// 统计报告接口
async function getMallReportsSvc(params: {
  mall_id: number,
  duration: string,
  page: number,
  rows: number,
  user_key: string,
}): Promise<any> {
  const url = 'getMallReports';
  const getData = await getAxiosDatas(params, url, 'inners_api/');
  const res = getData as Result<any>;
  return res;
}
// 统计报告数据导出客流统计
async function getMallRankDataBasesMallRankingSvc(params: {
  mall_id: number,
  duration: string,
  type: string,
  property: string,
  subtype: string,
  date: string
}, showInfoWindow = true): Promise<any> {
  const url = 'getMallRankDataBasesMallRanking';
  const getData = await getAxiosDatas(params, url, 'inners_api/', showInfoWindow);
  const res = getData as Result<any>;
  return res;
}
// 统计报告新客流
async function getMallPortaitsByMallIdSSvc(params: {
  mall_id: number,
  duration: string,
  type: string,
  property: string,
  date_ary: string[],
}, showInfoWindow = true): Promise<any> {
  const url = 'getMallPortaitsByMallIdS';
  const getData = await getAxiosDatas(params, url, 'inners_api/', showInfoWindow);
  const res = getData as Result<any>;
  return res;
}
// 客流统计报告-商圈分析商圈信息
async function getGridBussinessCircleWeekMonthSvc(params: {
  mall_id: number,
  date: string,
  start_percent: number,
  end_percent: number,
  property: number,
  type: number,
  duration: string
}, showInfoWindow = true): Promise<any> {
  const url = 'gridprofile/getGridBussinessCircleWeekMonth';
  const getData = await getAxiosDataNode(params, url, showInfoWindow);
  const res = getData as Result<any>;
  return res;
}
// 客流统计报告-商圈分析格子信息
async function getMallRankingListWeekMonthSvc(params: {
  date: string,
  mall_id: number,
  duration: string,
}, showInfoWindow = true): Promise<any> {
  const url = 'getMallRankingListWeekMonth';
  const getData = await getAxiosDatas(params, url, 'inners_api/', showInfoWindow);
  const res = getData as Result<any>;
  return res;
}
// 客流统计报告-竞争排名
async function getBusinessPopulationSingleWeekMonthSvc(params: {
  adcode: string,
  date: string,
  mall_id: number,
  duration: string
}): Promise<any> {
  const url = 'getBusinessPopulationSingleWeekMonth';
  const getData = await getAxiosDatas(params, url, 'inners_api/');
  const res = getData as Result<any>;
  return res;
}
// 本地外地客流占比
async function getLocaltiyAndOutsidePassengerFlowSSvc(params: {
  mall_id: number,
  date_ary: string[],
  duration: string
}): Promise<any> {
  const url = 'getLocaltiyAndOutsidePassengerFlowS';
  const getData = await getAxiosDatas(params, url, 'inners_api/');
  const res = getData as Result<any>;
  return res;
}

async function getMallRankDataByMallIdSvc(params: {
  date: string,
  start: number,
  end: number,
  mall_id: number,
  duration: string,
},  showInfoWindow = true): Promise<any> {
  const url = 'getMallRankDataByMallId';
  const getData = await getAxiosDatas(params, url, 'inners_api/', showInfoWindow);
  const res = getData as Result<any>;
  return res;
}
// 竞争烈度
async function getGridPerAgePortraitByCompetemallIdSvc(params: {
  mall_id: number,
  compete_mall_id: string,
  date: string,
  type: number,
  duration: string,
  j_type: string
}): Promise<any> {
  const url = 'getGridPerAgePortraitByCompetemallId';
  const getData = await getAxiosDatas(params, url, 'inners_api/');
  const res = getData as Result<any>;
  return res;
}
// 4s店
async function getGridPerAgePortraitCompetitionInterceptSSvc(params: {
  mall_id: number,
  compete_mall_id: string,
  date: string,
  type: number,
  duration: string,
  order_by: string,
  top_start: number
  top_end: number
}): Promise<any> {
  const url = 'getGridPerAgePortraitCompetitionInterceptS';
  const getData = await getAxiosDatas(params, url, 'inners_api/');
  const res = getData as Result<any>;
  return res;
}
// 竞争烈度客流对比
async function getPassengerFlowOverlapSvc(params: {
  mall_id: number,
  compete_mall_id: string,
  date: string,
  duration: string,
}): Promise<any> {
  const url = 'getPassengerFlowOverlap';
  const getData = await getAxiosDatas(params, url, 'inners_api/');
  const res = getData as Result<any>;
  return res;
}
async function getPassengerFlowDailySvc(params: {
  mall_id: number,
  compete_mall_id: string,
  date: string,
  m_duration: string
}): Promise<any> {
  const url = 'getPassengerFlowDaily';
  const getData = await getAxiosDatas(params, url, 'inners_api/');
  const res = getData as Result<any>;
  return res;
}
export {
  getGridPerAgePortraitInterceptS,
  getGridPerAgePortraitCompetitionInterceptSSvc,
  getPassengerFlowDailySvc,
  getPassengerFlowOverlapSvc,
  getGridPerAgePortraitByCompetemallIdSvc,
  getMallRankDataByMallIdSvc,
  getLocaltiyAndOutsidePassengerFlowSSvc,
  getBusinessPopulationSingleWeekMonthSvc,
  getMallRankingListWeekMonthSvc,
  getGridBussinessCircleWeekMonthSvc,
  getMallPortaitsByMallIdSSvc,
  getMallRankDataBasesMallRankingSvc,
  getMallReportsSvc,
  getMarketPenetrationSvc as marketPenetrationSvc,
  getMallBusinessCore as businessCore,
  getMallFlowAndNumDataByMallId as passengerFlowData,
  getLocaltiyAndOutsidePassengerFlowS as customerOriginAnalysis,
  getGridPerAgePortraitByMallIdS as perAgePortrait,
  getCityOutsidePassengerFlowTopS as passengerFlowTops,
  getCityOutsidePassengerFlowTopS1 as passengerFlowTopsArray,
  getLengthsOfStayData as stayHour,
  getGridPerAgePortraitS as perAgePortraits,
  getMallTotalPassengerFlow as mallTotalFlow,
  getGridPerAgePortraitByMallId as portraitByMallId,
  getGridMallPerAgeNumTheSum as perAgeNumTheSum,
  getGridMallAnalysisConsumptionAbility as mallAnalysisConsumptionAbility,
  getAoiDepressionRank as aoiDepressionRank,
  getMallRankDataByType as MallRankDataByType,
  getMallRankDataByTypes as getMallRankDataByTypes,
  getGridDepressionData as gridDepressionData,
  getCompetedMallByUserId,
  getCompetedMallIds,
  getGridBussinessCircleS,
  getPassengerFlowDistribute,
  getCompetitionIntensityData,
  getAoiPortrait,
};
















































































import { prefix, privacyMallInfo, mallRankListObj } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { LabelStyle, MallRankParams } from '@/types/store';
import {
  MallRankDataByType,
  getMallRankDataByTypes,
  getCompetedMallByUserId,
  getCompetedMallIds,
} from '@/services/api';
import rankFilter from '@/components/rankFilter/index.vue';
import rankTable from '@/components/rankTable/index.vue';
import mallRankListInfo from '@/components/mallRankListInfo/index.vue';
import { ConfigTypeMap } from 'dayjs';
import gradientCard from '@/components/GradientCard/index.vue';
import {
  clearMall,
  drawMall,
  fillColorPage,
  getMapCenter,
  getSectionAry,
  zoomUp,
  zoomDown,
} from '@/components/map/map';
import mallName from '@/components/mallName/index.vue';
import detailedAnalysis from '@/components/detailedAnalysis/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
import { getContainer, processObj } from '@/components/index';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
@Component({
  components: {
    rankFilter,
    rankTable,
    gradientCard,
    mallName,
    spinCard,
    mallInfo: mallRankListInfo,
    detailedAnalysis,
  },
})
export default class Environment extends Vue {
  $dayjs: any;
  prefix: string = prefix;
  typeObj = mallRankListObj[1];
  tableWh = 380;
  text = '1平方公里';
  singleColor = 'blue';
  titleDisplay = false;
  colorAry = ['#2947EF', '#4466FB', '#76B7FF', '#4AC3FF'].reverse();
  gradientWidth = 62;
  gradientList = [
    {
      id: 1,
      color: this.colorAry[0],
      width: this.gradientWidth,
    },
    {
      id: 2,
      color: this.colorAry[1],
      width: this.gradientWidth,
    },
    {
      id: 3,
      color: this.colorAry[2],
      width: this.gradientWidth,
    },
    {
      id: 4,
      color: this.colorAry[3],
      width: this.gradientWidth,
    },
  ];
  gradientValue = [
    {
      id: 1,
      num: 0,
    },
    {
      id: 2,
      num: 0,
    },
    {
      id: 3,
      num: 0,
    },
    {
      id: 4,
      num: 0,
    },
  ];
  $jQ: any;
  $stores: any;
  spinShow = true;
  table: any = [];
  data: any = [];
  map: any;
  filterValue: number[] = [0, 100];
  polygon: any;
  polygonColor = '#468ee6';
  activePolygonColor = '#e64652';
  duration = '';
  mallId = NaN;
  mallName = '';
  date = '';
  dates = '';
  boundary = '';
  center = '';
  adcode = '';
  params!: any;
  circleAry: any = [];
  polygonAry: any = [];
  rankLabelAry: any = [];
  nameLabelAry: any = [];
  activePolygon!: any;
  privacyInfo = '';
  labelStyle: LabelStyle = {
    color: 'white',
    border: 'none',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    transform: 'translateY(-50%) translateX(-50%)',
    zIndex: 10,
  };
  infoStyle = {
    zIndex: 10,
    transform: 'translateY(-120%) translateX(-53%)',
    backgroundColor: '#fff',
    color: '#030227',
    borderRadius: '6px',
    border: '0px',
  };
  moveStyle =
  'display:flex;justify-content:space-between;align-items:center;padding:6px 0;font-weight:bolder;';
  moveLabel!: any;
  mapOverlayConfig: any = {
    18: {
      radius: 10,
      nameVisible: true,
      rankVisible: true,
      strokeWeight: 5,
    },
    17: {
      radius: 20,
      nameVisible: true,
      rankVisible: true,
      strokeWeight: 5,
    },
    16: {
      radius: 40,
      nameVisible: true,
      rankVisible: true,
      strokeWeight: 5,
    },
    15: {
      radius: 80,
      nameVisible: false,
      rankVisible: true,
      strokeWeight: 5,
    },
    14: {
      radius: 100,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 5,
    },
    13: {
      radius: 120,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    12: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    11: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    10: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    9: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    8: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    7: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    6: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    5: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    4: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    3: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    2: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    1: {
      radius: 200,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
  };
  initZom = 16;
  initRadius = 40;
  environmentPage: any;
  title = '';
  tipInfo = '';
  column = '';
  mallMarker!: any;
  mapHeight = '0';
  lockMallId = '0';
  overlayEvent: any = [];
  mapevent: any = [];
  mallInfoStatus = false;
  competedData: any = [];
  mallInfoBtnStatus = true;
  labelInfoStatus = true;
  detailedAnalysisStatus = false;
  // min max average center current
  valueSectionData = this.sectionObj();
  sectionData: any = [];
  displayType = 0;
  currentName = '';
  popActiveStatus = 0;
  percentActiveStatus = 0;
  type2Status = false;
  typeClick = 1;
  buriedPointRank = '';
  buriedPointHandler(buriedPoint: string, params?: any): void {
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  @Watch('$store.state.global.downloadStatus')
  async changeExport() {
    const dateObj = {
      d: this.date,
      xw: `${this.$dayjs(this.date).subtract(6, 'day')
        .format('YYYY-MM-DD')}~${
        this.date
      }`,
      xm: this.$dayjs(this.date).format('YYYY-MM'),
    };
    this.data.forEach((i: any) => {
      // eslint-disable-next-line no-param-reassign
      i.date = (dateObj as any)[this.duration];
    });

    const datas = [];
    const status = this.$store.state.global.downloadStatus;
    if (status === true) {
      // eslint-disable-next-line no-nested-ternary
      const text = this.duration === 'xw'
        ? `${this.title}-周数据`
        : this.duration === 'xm'
          ? `${this.title}-月数据`
          : this.title;
      datas.push({
        sheetData: this.data ? this.data : [],
        sheetName: text,
        sheetMultiHeader: [
          [`数据项:竞争环境-${this.title}`],
        ],
        sheetFilter: ['date', 'rank', 'name', 'value'],
        sheetHeader: ['日期', '排名', '区域名称', this.column],
        columnWidths: [8, 5, 15, 8],
      });
      // 周
      if (this.duration === 'xm' || this.duration === 'xw') {
        await this.setExportData(datas, this.duration);
      }
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
  async setExportData(datas: any[], duration: string) {
    if (duration === 'xm' || duration === 'xw') {
      const dateArr = []; // 单日的日期
      // dateArr.push(this.date);
      const nowDate = this.$dayjs(this.date);
      if (duration === 'xw') {
        for (let i = 0; i < 7; i++) {
          const currentDate = nowDate.subtract(i, 'day');
          dateArr.unshift(currentDate.format('YYYY-MM-DD'));
        }
      } else if (duration === 'xm') {
        // 获取当前月份的第一天
        const firstDay = nowDate.startOf('month');

        // 获取当前月份的最后一天
        const lastDay = nowDate.endOf('month');

        // 从第一天开始，依次增加日期，直到最后一天
        let currentDate = firstDay;
        while (currentDate.isSame(lastDay, 'day') || currentDate.isBefore(lastDay, 'day')) {
          dateArr.push(currentDate.format('YYYY-MM-DD'));
          currentDate = currentDate.add(1, 'day');
        }
      }
      const res = await getMallRankDataByTypes({
        adcode: typeof(this.adcode) === 'string' ? parseInt(this.adcode, 10) : this.adcode,
        dateArr,
        duration: this.duration,
        start: this.filterValue[0],
        end: this.filterValue[1],
        type: this.params.type,
        mallId: this.mallId,
      });
      let sheetData: any[] = [];
      if (res.status === 0) {
        for (const item of res.data) {
          if (item.status === 0) {
            for (const el of item.data.data) {
              el.date = item.date;
            }
            sheetData = sheetData.concat(item.data.data);
          }
        }
      }

      datas.push({
        sheetData,
        sheetName: `${this.title}-分天`,
        sheetMultiHeader: [
          [`数据项:竞争环境-${this.title}`],
        ],
        sheetFilter: ['date', 'rank', 'name', 'value'],
        sheetHeader: ['日期', '排名', '区域名称', this.column],
        columnWidths: [8, 5, 15, 8],
      });
    }
  }
  beforeDestroy(): void {
    setTimeout(() => {
      this.clear();
    }, 0);
  }

  clear(): void {
    // this.clearOverlay();
    this.$jQ(this.$el).remove();
    this.mapevent.forEach((item: any) => {
      (window as any).qq.maps.event.removeListener(item);
    });
    this.mapevent = [];
    this.map = null;
    this.data = [];
  }

  destroy(): void {
    (this.$el.parentNode as any).removeChild(this.$el);
    this.$destroy();
  }

  mounted(): void {
    this.$jQ('.map-zoom').css('right', this.tableWh + 20);
    this.mapHeight = `${getContainer()}px`;
    this.initMap('map-container', this.initZom);
    const { environmentDataType } = this.$store.state.mall;
    if (environmentDataType === 'pop') {
      this.popActiveStatus = 1;
      this.percentActiveStatus = 0;
    } else {
      this.popActiveStatus = 0;
      this.percentActiveStatus = 1;
    }
    this.pageHandler();
  }

  sectionObj(): any {
    const name = this.column;
    const name2 = `全市商场${this.column}`;
    const minName = `${name2}最小值`;
    const maxName = `${name2}最大值`;
    const averageName = `${name2}平均数`;
    const centerName = `${name2}中位数`;
    const currentName = `当前商场${this.column}值`;
    const obj = {
      min: {
        type: {
          type: 'min',
          name,
          name2: minName.replaceAll('(%)', ''),
          color: '#F831F0',
        },
        value: 0,
        count: 0,
      },
      max: {
        type: {
          type: 'max',
          name,
          name2: maxName.replaceAll('(%)', ''),
          color: '#317DF6',
        },
        value: 0,
        count: 0,
      },
      average: {
        type: {
          type: 'average',
          name,
          name2: averageName.replaceAll('(%)', ''),
          color: '#A228F5',
        },
        value: 0,
        count: 0,
      },
      center: {
        type: {
          type: 'center',
          name,
          name2: centerName.replaceAll('(%)', ''),
          color: '#4034F3',
        },
        value: 0,
        count: 0,
      },
      current: {
        type: {
          type: 'current',
          name,
          name2: currentName.replaceAll('(%)', ''),
          color: '#2EC7E4',
        },
        value: 0,
        count: 0,
      },
    };
    return obj;
  }
  // mallInfoClickHandler
  mallInfoClickHandler(): void {
    this.buriedPointHandler('main_compete_rank_click');
    if (this.competedData.length === 0) {
      const text = '主场项目和竞品项目暂无排行榜相关数据';
      this.$Modal.info({
        title: this.title,
        content: text,
        okText: '知道了',
      });
      return;
    }
    this.updateMallInfoStatus(true);
    (this.$refs.mallInfo as any).changeData();
  }

  updateMallInfoStatus(status: boolean): void {
    this.mallInfoStatus = status;
  }

  async dataTypeClickHandler(type: string): Promise<void> {
    this.spinShow = true;
    const { type: types, type2 } = this.typeObj;
    if (type === 'pop') {
      this.popActiveStatus = 1;
      this.percentActiveStatus = 0;
      this.params.type = type2;
      this.typeClick = 1;
      // await this.dataHandler();
      this.$stores.mall.setEnvironmentDataType(type);
      this.$stores.pageConfig.setMallUpdateKey(`environmentMallUpdate${type}`);
    } else if (type === 'percent') {
      this.popActiveStatus = 0;
      this.percentActiveStatus = 1;
      this.params.type = types;
      this.typeClick = 2;
      // await this.dataHandler();
      this.$stores.mall.setEnvironmentDataType(type);
      this.$stores.pageConfig.setMallUpdateKey(`environmentMallUpdate${type}`);
    } else {
      console.log('暂无匹配');
    }
    this.spinShow = false;
  }

  updatDetailedAnalysisStatus(status: boolean): void {
    this.detailedAnalysisStatus = status;
  }

  getTableByType(): any {
    const titles = this.type2Status && this.popActiveStatus === 1 ? '客流人数' : this.column;
    const table = [
      {
        title: '排名',
        key: 'rank',
        sortable: true,
        render: (h: any, params: any) => {
          const { rank, mall_id: mallId } = params.row;
          return h('div', {
            attrs: {
              ids: mallId,
            },
            domProps: {
              innerText: rank,
            },
          });
        },
        width: 80,
      },
      {
        title: '区域名称',
        key: 'name',
        // sortable: true,
        width: 110,
        tooltip: true,
      },
      {
        title: titles,
        key: 'value',
        sortable: true,
        width: 140,
        render: (h: any, params: any) => {
          const { value } = params.row;
          return h('div', [
            h('div', {
              style: {
                textAlign: 'center',
              },
              domProps: {
                innerText: value,
              },
            }),
            h('Icon', {
              class: 'region-unlock region-locks',
              props: {
                type: 'ios-unlock-outline',
                size: 'small',
              },
              style: {
                fontSize: '19px',
                color: 'black',
                fontWeight: 'bolder',
                paddingLeft: '10px',
                display: 'none',
              },
            }),
            h('Icon', {
              class: 'region-lock region-locks',
              props: {
                type: 'ios-lock-outline',
                size: 'small',
              },
              style: {
                fontSize: '19px',
                color: 'black',
                fontWeight: 'bolder',
                paddingLeft: '10px',
                display: 'none',
              },
            }),
          ]);
        },
        // <Icon type="ios-lock-outline" />
      },
    ];
    return table;
  }

  async pageHandler(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.spinShow = true;
    const { mall, pageConfig } = this.$store.state;
    const {
      boundary,
      center,
      date,
      mallId,
      adcode,
      duration,
      dates,
      mallName,
    } = mall;
    this.filterValue = pageConfig.filterValue;
    this.boundary = boundary;
    this.center = center;
    this.clearOverlay();

    if (this.center) {
      const center = getMapCenter(`${Number(this.center.split(',')[0]) + 0.0007},${
        Number(this.center.split(',')[1]) - 0.0002
      }`);

      this.map.setCenter(center);
      this.map.panBy(206, 0);
      clearMall(this.mallMarker);
      this.mallMarker = drawMall(this.map, center, 2);
    }
    this.date = date;
    this.dates = dates;
    this.mallId = mallId;
    this.mallName = mallName;
    this.adcode = adcode;
    this.duration = duration;
    const { path } = this.$route;
    const pathAry = path.split('/');
    const types = pathAry[pathAry.length - 1];
    this.typeObj = mallRankListObj[types];
    const { type, name, column, displayType, type2, buriedPointRank, tipInfo } = this.typeObj;
    this.column = column;
    this.displayType = displayType;
    this.title = name;
    this.tipInfo = tipInfo;
    let type2s = type;
    if (type2 && this.popActiveStatus) {
      type2s = type2;
      this.column = '客流(人数)';
      this.displayType = 0;
    }
    this.type2Status = type2;
    this.buriedPointRank = buriedPointRank;
    this.params = {
      adcode: typeof(this.adcode) === 'string' ? parseInt(this.adcode, 10) : this.adcode,
      date: this.date,
      duration: this.duration,
      start: this.filterValue[0],
      end: this.filterValue[1],
      type: type2s,
      mallId: this.mallId,
    };
    this.valueSectionData = this.sectionObj();

    await this.dataHandler();
    this.spinShow = false;
    const params = {
      mall_id: this.mallId,
    };
    const competedRes = await getCompetedMallByUserId(params);
    let datas = [];
    if (competedRes.status === 0 && competedRes.data.length > 0) {
      console.log('已绑定竞品');
      const { data } = competedRes;
      datas = data;
    } else {
      console.log('暂无绑定竞品');
    }
    this.getCompetedData(datas);
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }
  processValueSectionData(average: number): any {
    if (this.data.length === 0) {
      this.valueSectionData = this.sectionObj();
      return;
    }
    const lth = Number(this.data.length) - 1;
    const maxLth = lth;
    // eslint-disable-next-line radix
    const centerLth = parseInt(`${lth / 2}`);
    const valueMin = this.data[maxLth].value;
    const valueMax = this.data[0].value;
    const valueCenter = this.data[centerLth].value;
    this.valueSectionData.min.value = valueMin;
    this.valueSectionData.max.value = valueMax;
    this.valueSectionData.center.value = valueCenter;
    this.valueSectionData.average.value = average;
  }
  async getCompetedData(datas: any): Promise<void> {
    let mall_ids = '';
    const mallInfoObj: any = {};
    // data: 0正常  1暂无数据  2 低于隐私
    datas.forEach((item: any) => {
      const { mall_id, name } = item;
      mall_ids += `${mall_id},`;
      const obj = {
        mall_id,
        name,
        dataStatus: 1,
        type: '竞品',
      };
      mallInfoObj[mall_id] = obj;
    });
    mall_ids = mall_ids + this.mallId;
    mallInfoObj[this.mallId] = {
      mall_id: this.mallId,
      name: this.mallName,
      dataStatus: 1,
      type: '主场',
    };
    const params = {
      mall_id: this.mallId,
      cmall_ids: mall_ids,
      duration: this.duration,
      date: this.date,
    };
    const competedRes = await getCompetedMallIds(params);
    // const info = '';
    if (competedRes.status === 0 && competedRes.data.length > 0) {
      const { data } = competedRes;
      const dataObj = processObj(data, 'mall_id');
      if (!Object.prototype.hasOwnProperty.call(dataObj, this.mallId)) {
        console.log('主场无数据-可能区域刚创建，不满足T+1天，也可能区域状态为非标项目');
      } else if (data.length === 1) {
        console.log('竞品有数据');
      }

      Object.values(mallInfoObj).forEach((item: any) => {
        const { mall_id: mallId } = item;
        if (Object.prototype.hasOwnProperty.call(dataObj, mallId)) {
          mallInfoObj[mallId].dataStatus = 0;
        } else {
          console.log('主场/竞品暂无数据');
          mallInfoObj[mallId].dataStatus = 1;
        }
      });
    } else {
      console.log('主场&竞品无数据');
      Object.values(mallInfoObj).forEach((item: any) => {
        const { mall_id: mallId } = item;
        mallInfoObj[mallId].dataStatus = 1;
      });
    }
    let info = '';
    let compteInfo = '';
    Object.values(mallInfoObj).forEach((item: any) => {
      const { dataStatus, name, type } = item;
      if (dataStatus === 1) {
        const str = type === '主场' ? `${name}${type}` : `${name}`;
        info += `${str}&`;
        if (type === '竞品') {
          compteInfo = '竞品';
        }
      }
    });
    info = info.slice(0, info.length - 1);
    this.privacyInfo = info ? info + compteInfo + privacyMallInfo : '';
    console.log(this.privacyInfo);
    this.competedData = competedRes.data.length > 0 ? competedRes.data : [];
    setTimeout(() => {
      this.mallInfoBtnStatus = false;
    }, 600);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async dataHandler(): Promise<void> {
    let data: any = [];
    this.data = [];
    let average = 0;
    this.table = this.getTableByType();
    const res = await this.getMallRankDataByType(this.params);
    data = res.data;
    average = res.average ? res.average : 0;
    if (!data) {
      this.data = [];
      this.spinShow = false;
      return;
    }
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (data && data[0]) {
      const value1 = data[0].value;
      let weightType = 1;
      if (String(value1).indexOf('.') > -1) {
        weightType = 2;
      }
      const sectionAry = getSectionAry(
        data,
        'value',
        this.colorAry,
        weightType,
      );
      this.gradientValue = [];
      sectionAry.forEach((item: any, index: number) => {
        const obj = {
          id: index + 1,
          num: item.max,
        };
        this.gradientValue.push(obj);
      });
      this.clearOverlay();
      const zoom = this.map.getZoom() || this.initZom;
      const { nameVisible, rankVisible, radius, strokeWeight } = this.mapOverlayConfig[zoom];
      this.data = data;
      data.forEach((item: any, index: number) => {
        const { center_gcj: centerGcj, mall_id: mallId, name, value } = item;
        const { rank } = item;
        (this.data[index] as any).index = index;
        const latLng = centerGcj.split(',');
        const center = this.overlayCenter(latLng[0], latLng[1]);
        const fillColor = fillColorPage(value, sectionAry);
        // 圆形覆盖物
        const circle = this.drawCircle(
          this.map,
          center,
          index,
          mallId,
          radius,
          0,
          item,
          3,
          fillColor,
        );
        // 边界覆盖物 2021-09-02 取消边界覆盖物 不展示MALL边界。
        // const polygon = this.drawPolygon(this.map, path, index, mallId, this.polygonColor, polygonVisible, 1);
        // 排名覆盖物
        const rankContent = `<div style="width: 100%;height:100%;text-align:center;line-height: 1.8em;font-size: 18px;">${rank}</div>`;
        const rankLabel = this.drawLabel(
          this.map,
          center,
          rankContent,
          index,
          mallId,
          this.labelStyle,
          rankVisible,
        );
        // 名称覆盖物
        const nameContent = `<div class="theme-text-color" style="width: 100%;height:100%;text-align:center;font-size: 14px;margin-top:54px;">${name}</div>`;
        const nameLabel = this.drawLabel(
          this.map,
          center,
          nameContent,
          index,
          mallId,
          this.labelStyle,
          nameVisible,
        );
        this.circlesHover(circle);
        this.circlesClick(circle);
        this.circleAry.push(circle);
        this.rankLabelAry.push(rankLabel);
        this.nameLabelAry.push(nameLabel);
        if (index === 0) {
          circle.setStrokeWeight(strokeWeight);
          circle.active = 1;
        }
      });
    }
    this.processValueSectionData(average);
  }
  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  clearOverlay(): void {
    if (this.circleAry.length === 0) {
      return;
    }
    this.circleAry.forEach((item: any, index: number) => {
      item.setMap(null);
      this.rankLabelAry[index].setMap(null);
      this.nameLabelAry[index].setMap(null);
    });
    // 地图所有overlay得集合
    this.overlayEvent.forEach((item: any) => {
      (window as any).qq.maps.event.removeListener(item);
    });
    this.circleAry = [];
    this.rankLabelAry = [];
    this.nameLabelAry = [];
    this.overlayEvent = [];
    this.data = [];
  }

  overlayCenter(lat: string, lng: string): string {
    return new (window as any).qq.maps.LatLng(parseFloat(lat), parseFloat(lng));
  }

  overlayPath(lat: string, lng: string): string {
    return new (window as any).qq.maps.LatLng(parseFloat(lat), parseFloat(lng));
  }

  clearStrokeWeight(): void {
    this.circleAry.forEach((item: any) => {
      if (item.active === 1) {
        item.setStrokeWeight(0);
        // eslint-disable-next-line no-param-reassign
        item.active = 0;
      }
    });
  }

  circlesClick(circle: ConfigTypeMap): void {
    const overlayEvent = (window as any).qq.maps.event.addListener(
      circle,
      'click',
      (event: any) => {
        // const { index } = event.target as any;
        // if (index === this.rowActive) {
        // this.spinShow = false;
        // return;
        // }
        this.clearStrokeWeight();
        const zoom = this.map.getZoom();
        const { strokeWeight } = this.mapOverlayConfig[zoom];
        (event.target as any).setStrokeWeight(strokeWeight);
        // eslint-disable-next-line no-param-reassign
        (event.target as any).active = 1;
        // this.rowActive = index;
        const row = event.target.item;
        // 调用自组件方法，执行交互渲染
        (this.$refs.tables as any).tableClickHandler(row, 1);
        this.labelInfoStatus = false;
      },
    );
    this.overlayEvent.push(overlayEvent);
  }

  circlesHover(circle: ConfigTypeMap): void {
    const overlayEvent = (window as any).qq.maps.event.addListener(
      circle,
      'mouseover',
      (event: Event) => {
        this.clearLabel(this.moveLabel);
        const { item } = event.target as any;
        const {
          mall_id: mallId,
          name,
          rank,
          value,
          center_gcj: centerGcj,
        } = item;
        const content = `<div style='border:none !important;user-select:none;text-align:center;font-size: 14px;padding:10px 20px;position:relative;'>
      <ul class='move-info'>
        <li style=${this.moveStyle}><span style="margin-right:140px;">${name}</span><span class="detailed-analysis-btn theme-text-color cursor">详细分析</span></li>
        <li style=${this.moveStyle}><span>排名：</span><span>${rank}</span></li>
        <li style=${this.moveStyle}><span style="margin-right:140px;">${this.column}</span><span>${value}</span></li>
      </ul>
      <div style='position: absolute;width: 0px ;height: 0px;left:50%;bottom:-6%;line-height: 0px;border-width: 20px 16px 0;border-style: solid dashed dashed dashed;border-left-color: transparent;border-right-color: transparent;color: #fff;'></div></div>`;
        const latLng = centerGcj.split(',');
        const center = this.overlayCenter(latLng[0], latLng[1]);
        this.currentName = name;
        // this.moveLabel = this.drawLabel2(this.map, center, content, 0, mallId, this.infoStyle, true);
        this.moveLabel = new (window as any).qq.maps.Label({
          map: this.map,
          position: center,
          content,
          style: this.infoStyle,
          zIndex: 2,
          index: 0,
          mallId,
          visible: true,
        });
        this.labelInfoStatus = true;
        setTimeout(() => {
          this.$jQ('.detailed-analysis-btn').unbind('click');
          this.$jQ('.detailed-analysis-btn').click(() => {
            this.updatDetailedAnalysisStatus(true);
            this.valueSectionData.current.value = value;
            this.changeDataInfo();
          });
        }, 1000);
      },
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const overlayEvent1 = (window as any).qq.maps.event.addListener(
      circle,
      'mouseout',
      () => {
        if (this.labelInfoStatus) {
          this.clearLabel(this.moveLabel);
        }
      },
    );
    this.overlayEvent.push(overlayEvent);
    this.overlayEvent.push(overlayEvent1);
  }

  changeDataInfo(): void {
    let data: any = [];
    if (this.data.length === 0) {
      data = this.sectionObj();
    } else {
      data = Object.values(this.valueSectionData);
      data = data.sort((x: any, y: any) => x.value - y.value);
      let k1 = 0;
      let k2 = 0;
      let k3 = 0;
      let k4 = 0;
      this.data.forEach((item: any) => {
        const { value, type } = item;
        if (value >= data[0].value && value < data[1].value) {
          k1 += 1;
        }
        if (value >= data[1].value && value < data[2].value) {
          k2 += 1;
        }
        if (value >= data[2].value && value < data[3].value) {
          k3 += 1;
        }
        if (value >= data[3].value && value < data[4].value) {
          k4 += 1;
        }
      });
      data[0].count = 0;
      data[1].count = k1;
      data[2].count = k2;
      data[3].count = k3;
      data[4].count = k4;
    }
    this.sectionData = data;
    // (this.$refs.detailedAnalysis as any).changeData(data);
  }

  initMap(el: string, zoom: number): void {
    this.map = new (window as any).qq.maps.Map(document.getElementById(el), {
      center: new (window as any).qq.maps.LatLng(22.53793, 114.0683),
      mapStyleId: 'style1',
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      zoom,
    });

    this.mapZoomEvent();
  }

  drawPolygon(
    map: ConfigTypeMap,
    path: string[],
    index: number,
    mallId: number,
    color: string,
    visible: boolean,
    zIndex: number,
  ): any {
    const polygon = new (window as any).qq.maps.Polygon({
      map,
      path,
      strokeWeight: 3,
      editable: false,
      strokeColor: color,
      cursor: 'default',
      zIndex,
      visible,
      index,
      mallId,
    });
    return polygon;
  }

  drawCircle(
    map: ConfigTypeMap,
    center: string,
    index: number,
    mallId: number,
    radius: number,
    strokeWeight: number,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    item: any,
    zIndex: number,
    fillColor: string,
  ): any {
    const circle = new (window as any).qq.maps.Circle({
      map,
      center,
      radius,
      // fillColor: '#2947ef',
      fillColor,
      strokeWeight,
      strokeColor: '#FF0000',
      zIndex,
      index,
      mallId,
      item,
    });
    return circle;
  }

  drawLabel(
    map: ConfigTypeMap,
    position: string,
    content: string,
    index: number,
    mallId: number,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    style: any,
    visible: boolean,
  ): any {
    const label = new (window as any).qq.maps.Label({
      map,
      position,
      content,
      style,
      zIndex: 2,
      index,
      mallId,
      visible,
    });
    return label;
  }

  drawLabel2(
    map: ConfigTypeMap,
    position: string,
    content: string,
    index: number,
    mallId: number,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    style: any,
    visible: boolean,
  ): any {
    const label = new (window as any).qq.maps.Label({
      map,
      position,
      content,
      style,
      zIndex: 2,
      index,
      mallId,
      visible,
    });
    return label;
  }
  /* eslint-disable no-param-reassign */

  mapZoomEvent(): void {
    const event = (window as any).qq.maps.event.addListener(
      this.map,
      'zoom_changed',
      (event: Event) => {
        const { zoom } = event.target as any;
        let config = this.mapOverlayConfig[zoom];
        if (!config) {
          config = {
            radius: 200,
            nameVisible: false,
            rankVisible: false,
          };
        }
        const { radius, nameVisible, rankVisible, strokeWeight } = config;
        this.circleAry.forEach((item: any, index: number) => {
          this.circleAry[index].setRadius(radius);
          item.setRadius(radius);
          if (item.active === 1) {
            item.setStrokeWeight(strokeWeight);
          }
          const nameLabelItem = this.nameLabelAry[index];
          const rankLabelItem = this.rankLabelAry[index];
          nameLabelItem.setVisible(nameVisible);
          rankLabelItem.setVisible(rankVisible);
        });
      },
    );
    this.mapevent.push(event);
  }

  processPolygonData(boundary: string): any {
    const path: any[] = [];
    const boundaryAry = boundary.split('|');
    boundaryAry.pop();
    boundaryAry.forEach((item: string) => {
      const latLng = new (window as any).qq.maps.LatLng(
        parseFloat(item.split(',')[0]),
        parseFloat(item.split(',')[1]),
      );
      path.push(latLng);
    });
    return path;
  }

  async getMallRankDataByType(params: MallRankParams): Promise<any> {
    const data = await MallRankDataByType(params);
    // const data = await MallRankDataByType(params);
    return data;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  clearPolygon(polygon: any): void {
    if (polygon) {
      polygon.setMap(null);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  clearLabel(label: any): void {
    if (label) {
      label.setMap(null);
    }
  }

  sliderChange(val: number[]): void {
    // 进度条数据变化返回
    const valStr1 = val.join('-');
    const valStr2 = this.filterValue.join('-');
    if (valStr1 === valStr2) {
      this.spinShow = false;
      return;
    }
    this.$stores.pageConfig.setFilterValue(val);
    this.$stores.pageConfig.setMallUpdateKey(`environmentMallUpdate${val}`);
    this.buriedPointHandler('color_click');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  processDataByStartEnd(start: number, end: number, data: any): void {
    const lth = data.length;
    const startTmps = String((start / 100) * lth);
    const endTmps = String((end / 100) * lth);
    // eslint-disable-next-line radix
    const startTmp = parseInt(startTmps);
    // eslint-disable-next-line radix
    const endTmp = parseInt(endTmps);
    const dataAry: any = [];
    let rank = 0;
    let sum = 0;
    data.forEach((item: any, index: number) => {
      if (index >= startTmp && index < endTmp) {
        // $val = $data[$i][$str];
        const { value } = item;

        if (value !== 0) {
          rank = rank + 1;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sum += value;
          item.rank = rank;
          dataAry.push(item);
        }
      }
    });
    return dataAry;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  rowClick(val: any): void {
    // 列表点击事件返回数据
    // this.rowActive = val.index;
    const { center_gcj: centerGcj } = val;
    const latLng = centerGcj.split(',');
    const center = this.overlayCenter(latLng[0], latLng[1]);
    // const path = this.processPolygonData(boundary);
    this.map.setCenter(center);
    this.map.setZoom(16);
    this.map.panBy(206, 0);
    const zoom = this.map.getZoom();
    const { strokeWeight } = this.mapOverlayConfig[zoom];
    this.circleAry.forEach((item: any) => {
      item.setStrokeWeight(0);
      item.active = 0;
      if (item.mallId === val.mall_id) {
        item.setStrokeWeight(strokeWeight);
        item.active = 1;
      }
    });
  }
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import { AxiosPromise, AxiosResponse } from 'axios';
import { Vue } from 'vue-property-decorator';
import { isJudgmentType } from '@/components/valiDate';
import qs from 'qs';
import { userModule } from '@/store/modules/user';
import { domainConfig } from '@/config/domain';
import { domainConfiNode } from '@/config/domain-node';

const domain: string = window.location.host;
const testUrlNode =  domain === 'local.qq.com:8080' ? 'http://local.qq.com:8082' : '';

const that = Vue.prototype;
export const getAxiosData = (data: any, url: string, baseUrl = 'inners_api/', showInfoWindow = true): AxiosPromise => {
  const login = getLogin();
  const { lbsUrl, domain, lbsUId } = login;
  data.domain = domain;
  data.lbs_u_id = lbsUId;
  return promiseHandler(data, lbsUrl, baseUrl, url, showInfoWindow);
};
export const getAxiosDataNode = (data: any, url: string, showInfoWindow = true): AxiosPromise => {
  const login = getLoginNode();
  const { lbsUrl } = login;
  // data.domain = domain;
  // data.lbs_u_id = lbsUId;
  const signs = paramsEncryption();
  const headers = {
    signs: JSON.stringify(signs),
  };
  return promiseHandlerNode(data, lbsUrl, url, headers, showInfoWindow);
};
export const getAxiosDataGetNode = (data: any, url: string): AxiosPromise => {
  const login = getLoginNode();
  const { lbsUrl } = login;
  const signs = paramsEncryption();
  const headers = {
    signs: JSON.stringify(signs),
  };
  return promiseHandlerGetNode(data, lbsUrl, url, headers);
};

export const getAxiosDatas = (data: any, url: string, baseUrl = 'inners_api/', showInfoWindow = true): AxiosPromise => {
  const login = getLogin();
  const { lbsUrl, domain, lbsUId, local } = login;
  data.domain = domain;
  if (url !== 'loginUser') {
    data.lbs_u_id = lbsUId;
  } else {
    data.local = local;
  }
  const params = paramsEncryption();
  data.params = params;
  return promiseHandler(data, lbsUrl, baseUrl, url, showInfoWindow);
};

// export const getAxiosDatasNode = (data: any, url: string): AxiosPromise => {
//   const login = getLoginNode();
//   const { lbsUrl } = login;
//   // data.domain = domain;
//   // if (url !== 'loginUser') {
//   //   data.lbs_u_id = lbsUId;
//   // } else {
//   //   data.local = local;
//   // }
//   // const params = paramsEncryption(data);
//   // data.params = params;
//   return promiseHandlerNode(data, lbsUrl, url);
// };

let showInfoWindow = false;
function promiseHandler(data: any, lbsUrl: string, baseUrl: string, url: string, showWindow = true): AxiosPromise {
  return new Promise((resolve, reject) => {
    that.$axios({
      method: 'post',
      url: `${lbsUrl}${baseUrl + url}.php`,
      data: qs.stringify(data),
    })
      .then((response: AxiosResponse) => {
        if (response.data.status === 0 || response.data.status === 1) {
          resolve(response.data);
        } else if (response.data.status === 3) {
          if (!showInfoWindow && showWindow) {
            showInfoWindow = true;
            that.$Modal.info({
              title: '客留通',
              content: '暂无该时间段服务权限',
              onOk: async () => {
                showInfoWindow = false;
              },
            });
          }
          resolve(response.data);
        } else {
          errorHandler(response, resolve);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err, 'error');
        reject(err);
      });
  });
}
function promiseHandlerNode(data: any, lbsUrl: string, url: string,  headers: any, showWindow = true): AxiosPromise {
  return new Promise((resolve, reject) => {
    that.$axios({
      method: 'post',
      url: `${lbsUrl}${url}`,
      data,
      headers,
    })
      .then((response: AxiosResponse) => {
        if (response.data.status === 0 || response.data.status === 1 || response.data.status === 2) {
          resolve(response.data);
        } else if (response.data.status === 3) {
          if (!showInfoWindow && showWindow) {
            showInfoWindow = true;
            that.$Modal.info({
              title: '客留通',
              content: '暂无该时间段服务权限',
              onOk: async () => {
                showInfoWindow = false;
              },
            });
          }
          resolve(response.data);
        } else {
          errorHandler(response, resolve);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err, 'error');
        reject(err);
      });
  });
}
function promiseHandlerGetNode(data: any, lbsUrl: string, url: string, headers: any): AxiosPromise {
  return new Promise((resolve, reject) => {
    that.$axios({
      method: 'get',
      url: `${lbsUrl}${url}`,
      params: data,
      headers,
    })
      .then((response: AxiosResponse) => {
        if (response.data.status === 0 || response.data.status === 1 || response.data.status === 2) {
          resolve(response.data);
        } else {
          errorHandler(response, resolve);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err, 'error');
        reject(err);
      });
  });
}

// 接口异常或登录失效执行函数
function errorHandler(response: AxiosResponse, resolve: any) {
  const { data, request } = response;
  const { responseURL } = request;
  const typeObj = isJudgmentType(data);
  const { type } = typeObj;
  if (type === 2) {
    const { msg } = data.info;
    const title = '客留通';
    let contents = '';
    if (msg === '所查询用户不存在') {
      const content = '抱歉，所查询用户不存在，请注册后再尝试。';
      that.$Modal.info({
        title,
        content,
        onOk: async () => {
          console.log('开平地址跳转');
        },
      });
      userModule.LogOuts();
      resolve('未再开平注册');
      contents = content;
    } else if (data.info === '用户已停止使用') {
      const content = '抱歉，该账号已停止使用，请联系我们重新开通。';
      that.$Modal.info({
        title,
        content,
        onOk: async () => {
          console.log('用户已停止使用');
        },
      });
      userModule.LogOuts();
      resolve('用户已停止使用');
      contents = content;
    } else if (data.info === '未再客留通注册账号') {
      const content = '抱歉，该账号暂无平台使用权限，请联系我们开通。';
      that.$Modal.info({
        title,
        content,
        onOk: async () => {
          console.log('未再客留通注册账号');
        },
      });
      resolve('未再客留通注册账号');
      contents = content;
      userModule.LogOuts();
    } else if (msg.includes('backend')) {
      // 接口异常
      const urlAry = responseURL.split('/');
      const url = urlAry[urlAry.length - 1];
      const content = '抱歉，该接口数据异常，请将以下报错信息复制给我们，我们会尽快为您处理。<br>' + `url: ${url}    <br> 报错信息：${msg}`;
      that.$Modal.error({
        title,
        content,
      });
      contents = content;
    }
    throw SyntaxError(contents);
  } else if (type === 3) {
    // 登录失效
    let title = '';
    let content = '';
    if (data.includes('session expired')) {
      title = '登录会话超时';
      content = '抱歉，该账号登录超时，请重新登录。';
    } else if (data.includes('illegal login status')) {
      title = '未登录';
      content = '抱歉，该账号尚未登录，请登录后再使用。';
    } else {
      title = '客留通';
      content = '抱歉，该账号异常，请您刷新或重新登录。如刷新后仍无法使用，请联系我们的产品运营人员辅助解决。';
    }
    userModule.LogOuts();
    const url = window.location.href;
    if (!url.includes('index') && userModule.token) {
      that.$Modal.info({
        title,
        content,
        onOk: async () => {
          that.$routers.push('/index');
          console.log('重新登录');
        },
      });
    }
    resolve('重新登录');
  }
}


function getLogin() {
  const { url, domain, local } = domainConfig;
  const { token } = userModule;
  return {
    domain,
    lbsUId: token,
    lbsUrl: url,
    local,
  };
}
function getLoginNode() {
  const { url, domain, local } = domainConfiNode;
  const { token } = userModule;
  return {
    domain,
    lbsUId: token,
    lbsUrl: testUrlNode + url,
    local,
  };
}

// 防止接口重复攻击
function paramsEncryption() {
  const noncestr = `mall${randomWord(true, 8, 10)}`;
  const timestamp = getTimestamp();
  // const requestData = JSON.parse(JSON.stringify(data));
  const password = 'MALLSERVER888LUCASYYE';
  const str = password + noncestr + timestamp;
  // if (Object.keys(requestData).length > 0) {
  //   str = str + JSON.stringify(requestData);
  // }
  const sign = that.$md5(str).toString();
  return {
    sign,
    timestamp,
    noncestr,
  };
}

function randomWord(randomFlag: boolean, min: number, max: number) {
  // * randomWord 产生任意长度随机字母数字组合
  // * randomFlag-是否任意长度 min-任意长度最小位[固定位数] max-任意长度最大位
  let str = '';
  let range = min;
  const arr = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  // 随机产生
  if (randomFlag) {
    range = Math.round(Math.random() * (max - min)) + min;
  }
  for (let i = 0; i < range; i++) {
    const pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
}

function getTimestamp() {
  // 获取时间戳
  const date = `${new Date()}`;
  const timestamp = String(Date.parse(date) / 1000);
  return timestamp;
}


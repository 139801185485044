import { getAxiosDataGetNode, getAxiosDataNode } from '@/services/apiConfig';
// import { getAxiosData, getAxiosDataNode } from '@/services/apiConfig';
import { Result } from '@/types/services';
import { Vue } from 'vue-property-decorator';
const that = Vue.prototype;

// const baseUrl = 'backstage_page/api/';
/*
* 接口类型：Post
* 编码类型：Content-Type:application/json
*/


// 申请客流来源区域
// 获取aoi区域
async function getAoiRegions(params = {}): Promise<any> {
  const url = 'aoi/getAoiRegions';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 删除aoi区域&&修改aoi区域名称
async function updateAoiRegionByIds(params = {}): Promise<any> {
  const url = 'aoi/updateAoiRegionByIds';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 修改aoi区域名称
async function updateManualAoiName(params = {}): Promise<any> {
  const url = 'aoi/updateManualAoiName';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 添加aoi区域
async function addAoi(params = {}): Promise<any> {
  const url = 'aoi/addAoi';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 查看区域
async function getAOILookOverInfo(params = {}): Promise<any> {
  const url = 'aoi/getAOILookOverInfo';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 获取当前用户绑定商场
async function getMallByUserId(params = {}): Promise<any> {
  const url = 'mall/getMallByUserId';
  const getData = await getAxiosDataGetNode(params, url);
  const res = getData as Result<any>;
  if (res.data.length === 0) {
    that.$Modal.info({
      title: '客留通',
      content: '当前用户暂无任何商场权限，请联系管理员！',
    });
  }
  return res;
}
// // 获取当前用户绑定商场
// async function getMallByUserId(params = {}): Promise<any> {
//   const url = 'getMallByUserId';
//   const getData = await getAxiosData(params, url);
//   const res = getData as Result<any>;
//   return res;
// }

// 获取商场网格区域
async function getGridBussinessCircle(params = {}): Promise<any> {
  const url = 'gridprofile/getGridBussinessCircle';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 获取aoi区域
async function getAoiAssociationManualByUserKeyAndMallId(params = {}): Promise<any> {
  const url = 'aoi/getAoiAssociationManualByUserKeyAndMallId';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 获取城市adcode
async function getCityAdcodeBycity(params = {}): Promise<any> {
  const url = 'adcode/getCityAdcodeBycity';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
export {
  getAoiRegions,
  updateAoiRegionByIds,
  updateManualAoiName,
  getAOILookOverInfo,
  getMallByUserId,
  getGridBussinessCircle,
  getAoiAssociationManualByUserKeyAndMallId as getAoiDataByMallId,
  getCityAdcodeBycity,
  addAoi,
};
